import React, { Component , Suspense } from 'react';
import { BrowserRouter,Routes, Route, Navigate } from 'react-router-dom';

const Story = React.lazy(() => import('./Components/Story'));
const Home = React.lazy(() => import('./Components/Home'));
const Games = React.lazy(() => import('./Components/Games'));
const Club = React.lazy(() => import('./Components/Club'));
const Contactus = React.lazy(() => import('./Components/Contactus'));
const Calendar = React.lazy(() => import('./Components/Calendar'));
const YaldaCalendar1403 = React.lazy(() => import('./Components/YaldaCalendar1403'));
const Invoice = React.lazy(() => import('./Components/Invoice'));
const GamesLand = React.lazy(() => import('./Components/GamesLand'));
const NowruzGame = React.lazy(() => import('./Components/NowruzGame'));
const Yalda = React.lazy(() => import('./Components/Yalda'));
const YaldaKhaton1403 = React.lazy(() => import('./Components/YaldaKhaton1403'));
const History = React.lazy(() => import('./Components/History'));
const Profile = React.lazy(() => import('./Components/Profile'));
const Terms = React.lazy(() => import('./Components/Terms'));
const PaymentFailed = React.lazy(() => import('./Components/PaymentFailed'));
const YaldaStory1403 = React.lazy(() => import('./Components/YaldaStory1403'));
const Nowruz1403 = React.lazy(() => import('./Components/Nowruz1403'));
const Nowruz1403Play = React.lazy(() => import('./Components/Nowruz1403Play'));
const Nowruz1404 = React.lazy(() => import('./Components/Nowruz1404'));
const Nowruz1404Play = React.lazy(() => import('./Components/Nowruz1404Play'));
const Yalda1402 = React.lazy(() => import('./Components/Yalda1402'));
const Yalda1403 = React.lazy(() => import('./Components/Yalda1403'));
const Blogs = React.lazy(() => import('./Components/Blogs'));
const Blog = React.lazy(() => import('./Components/Blog'));

class Site extends Component {
  constructor(props) {
    super(props);

    //برای حالتی که رندر سمت سرور داریم انجام میدم اطلاعات بایستی از این بخش به کامپوننتها تزریق بشه
    if (props.dataHolder !== null && props.dataHolder !== undefined)
    {
      this.state = {
        dataHolder:props.dataHolder,
        lang: props.dataHolder.lang
      }
    }
    else{
      var lang = 1;
      if (window != undefined && window!= null){
        //برخی چون en مستقل ندارند این شکلی نوشتم
        if (window.location.href.includes("en/") || window.location.href.includes("/en")) lang = 2;
      }
        
      this.state = {
        dataHolder:null,
        lang: lang
      }
    }
  }

  render() {
    return(
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route path="/" dataHolder={this.state.dataHolder} element={<Home lang={1} />}></Route>
          <Route path="/en/" dataHolder={this.state.dataHolder} element={<Home lang={2} />}></Route>

          <Route path="/nowruz-game/" dataHolder={this.state.dataHolder} element={<Games lang={1} />}></Route>
          <Route path="/nowruz-game/en/" dataHolder={this.state.dataHolder} element={<Games lang={2} />}></Route>

          <Route path="/club/" dataHolder={this.state.dataHolder} element={<Club lang={1} />}></Route>
          <Route path="/club/en/" dataHolder={this.state.dataHolder} element={<Club lang={2} />}></Route>

          <Route path="/contact-us/" dataHolder={this.state.dataHolder} element={<Contactus lang={1} ContactUs={false} />}></Route>
          <Route path="/contact-us/en/" dataHolder={this.state.dataHolder} element={<Contactus lang={2} ContactUs={false} />}></Route>

          <Route path="/about/" dataHolder={this.state.dataHolder} element={<Story lang={1} ContactUs={false} />}></Route>
          <Route path="/about/en/" dataHolder={this.state.dataHolder} element={<Story lang={2} ContactUs={false} />}></Route>

          <Route path="/nowruz-calendar-1403/" dataHolder={this.state.dataHolder} element={<Calendar id={45} lang={1} />}></Route>
          <Route path="/nowruz-calendar-1403/en/" dataHolder={this.state.dataHolder} element={<Calendar id={45} lang={2} />}></Route>

          <Route path="/nowruz-calendar-1404/" dataHolder={this.state.dataHolder} element={<Calendar id={63} lang={1} />}></Route>
          <Route path="/nowruz-calendar-1404/en/" dataHolder={this.state.dataHolder} element={<Calendar id={63} lang={2} />}></Route>

          <Route path="/yalda-calendar-1403/" dataHolder={this.state.dataHolder} element={<YaldaCalendar1403 id={46} lang={1} />}></Route>
          <Route path="/yalda-calendar-1403/en/" dataHolder={this.state.dataHolder} element={<YaldaCalendar1403 id={46} lang={2} />}></Route>

          <Route path="/payment/failed/" dataHolder={this.state.dataHolder} element={<PaymentFailed lang={1} />}></Route>
          <Route path="/payment/faileden/" dataHolder={this.state.dataHolder} element={<PaymentFailed lang={2} />}></Route>
          
          <Route path="/invoice/:id/" dataHolder={this.state.dataHolder} element={<Invoice lang={1} />}></Route>
          <Route path="/invoiceen/:id/" dataHolder={this.state.dataHolder} element={<Invoice lang={2} />}></Route>

          <Route path="/gamesland/" dataHolder={this.state.dataHolder} element={<GamesLand lang={1} invalidSize={0} />}></Route>
          <Route path="/gamesland/en/" dataHolder={this.state.dataHolder} element={<GamesLand lang={2} invalidSize={0} />}></Route>

          <Route path="/gamesland/invalid-size/" dataHolder={this.state.dataHolder} element={<GamesLand lang={1} invalidSize={1} />}></Route>          
          <Route path="/gamesland/invalid-size/en/" dataHolder={this.state.dataHolder} element={<GamesLand lang={2} invalidSize={1} />}></Route>          

          <Route path="/nowruz/" dataHolder={this.state.dataHolder} element={<NowruzGame id={4} lang={1} />}></Route>
          <Route path="/nowruz/en/" dataHolder={this.state.dataHolder} element={<NowruzGame id={4} lang={2} />}></Route>

          <Route path="/nowruz/:game_id/" dataHolder={this.state.dataHolder} element={<NowruzGame id={4} lang={1} />}></Route>
          <Route path="/nowruz/en/:game_id/" dataHolder={this.state.dataHolder} element={<NowruzGame id={4} lang={2} />}></Route>

          <Route path="/yalda-story-1402/yalda-khatoon-house/" dataHolder={this.state.dataHolder} element={<Yalda id={31} lang={1} />}></Route>
          <Route path="/yalda-story-1402/yalda-khatoon-house/en/" dataHolder={this.state.dataHolder} element={<Yalda id={31} lang={2} />}></Route>
          <Route path="/yalda-story-1402/yalda-khatoon-house/:game_id/" dataHolder={this.state.dataHolder} element={<Yalda id={31} lang={1} />}></Route>
          <Route path="/yalda-story-1402/yalda-khatoon-house/en/:game_id/" dataHolder={this.state.dataHolder} element={<Yalda id={31} lang={2} />}></Route>

          <Route path="/yalda-story-1403/yalda-khatoon-house/" dataHolder={this.state.dataHolder} element={<YaldaKhaton1403 id={47} lang={1} />}></Route>
          <Route path="/yalda-story-1403/yalda-khatoon-house/en/" dataHolder={this.state.dataHolder} element={<YaldaKhaton1403 id={47} lang={2} />}></Route>
          <Route path="/yalda-story-1403/yalda-khatoon-house/:game_id/" dataHolder={this.state.dataHolder} element={<YaldaKhaton1403 id={47} lang={1} />}></Route>
          <Route path="/yalda-story-1403/yalda-khatoon-house/en/:game_id/" dataHolder={this.state.dataHolder} element={<YaldaKhaton1403 id={47} lang={2} />}></Route>

          <Route path="/history/" dataHolder={this.state.dataHolder} element={<History lang={1} ContactUs={false} />}></Route>
          <Route path="/history/en/" dataHolder={this.state.dataHolder} element={<History lang={2} ContactUs={false} />}></Route>
          
          <Route path="/profile/" dataHolder={this.state.dataHolder} element={<Profile lang={1} ContactUs={false} />}></Route>
          <Route path="/profile/en/" dataHolder={this.state.dataHolder} element={<Profile lang={2} ContactUs={false} />}></Route>
          
          <Route path="/terms/" dataHolder={this.state.dataHolder} element={<Terms lang={1} digital={false} />}></Route>
          <Route path="/terms/en/" dataHolder={this.state.dataHolder} element={<Terms lang={2} digital={false} />}></Route>

          <Route path="/terms/digital/" dataHolder={this.state.dataHolder} element={<Terms lang={1} digital={true} />}></Route>
          <Route path="/terms/digital/en/" dataHolder={this.state.dataHolder} element={<Terms lang={2} digital={true} />}></Route>
          
          <Route path="/yalda1402/" dataHolder={this.state.dataHolder} element={<Yalda1402 lang={1} />}></Route>
          <Route path="/yalda1402/en/" dataHolder={this.state.dataHolder} element={<Yalda1402 lang={2} />}></Route>
          
          <Route path="/yalda-story-1403/" dataHolder={this.state.dataHolder} element={<Yalda1403 lang={1} />}></Route>
          <Route path="/yalda-story-1403/en/" dataHolder={this.state.dataHolder} element={<Yalda1403 lang={2} />}></Route>

          <Route path="/nowruz-game-1404/" dataHolder={this.state.dataHolder} element={<Nowruz1404 lang={1} />}></Route>
          <Route path="/nowruz-game-1404/en/" dataHolder={this.state.dataHolder} element={<Nowruz1404 lang={2} />}></Route>
          <Route path="/nowruz-game-1404/:game_id/" dataHolder={this.state.dataHolder} element={<Nowruz1404 lang={1} />}></Route>
          <Route path="/nowruz-game-1404/en/:game_id/" dataHolder={this.state.dataHolder} element={<Nowruz1404 lang={2} />}></Route>

          <Route path="/nowruz-game-1403/" dataHolder={this.state.dataHolder} element={<Nowruz1403 lang={1} />}></Route>
          <Route path="/nowruz-game-1403/en/" dataHolder={this.state.dataHolder} element={<Nowruz1403 lang={2} />}></Route>
          <Route path="/nowruz-game-1403/:game_id/" dataHolder={this.state.dataHolder} element={<Nowruz1403 lang={1} />}></Route>
          <Route path="/nowruz-game-1403/en/:game_id/" dataHolder={this.state.dataHolder} element={<Nowruz1403 lang={2} />}></Route>
          
          <Route path="/nowruz1403play/" dataHolder={this.state.dataHolder} element={<Nowruz1403Play id={36} lang={1} />}></Route>
          <Route path="/nowruz1403play/en/" dataHolder={this.state.dataHolder} element={<Nowruz1403Play id={36} lang={2} />}></Route>
          <Route path="/nowruz1403play/:game_id/" dataHolder={this.state.dataHolder} element={<Nowruz1403Play id={36} lang={1} />}></Route>
          <Route path="/nowruz1403play/en/:game_id/" dataHolder={this.state.dataHolder} element={<Nowruz1403Play id={36} lang={2} />}></Route>

          <Route path="/yalda-story/" dataHolder={this.state.dataHolder} element={<YaldaStory1403 lang={1} />}></Route>
          <Route path="/yalda-story/en/" dataHolder={this.state.dataHolder} element={<YaldaStory1403 lang={2} />}></Route>

          <Route path="/naneh-sarma-suitcase/:blog_id/" dataHolder={this.state.dataHolder} element={<Blog lang={1} />}></Route>
          <Route path="/naneh-sarma-suitcase/:blog_id/en" dataHolder={this.state.dataHolder} element={<Blog lang={2} />}></Route>
          
          <Route path="/naneh-sarma-suitcase/" dataHolder={this.state.dataHolder} element={<Blogs lang={1} />}></Route>
          <Route path="/naneh-sarma-suitcase/en/" dataHolder={this.state.dataHolder} element={<Blogs lang={2} />}></Route>

          <Route path="*" dataHolder={this.state.dataHolder} element={<Navigate to="/404/" />}></Route>           
        </Routes>
      </Suspense>
    );
  }
}
export default Site;